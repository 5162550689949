import React from 'react'; 
import ContentSection from '../ui/ContentSection';

export default function AboutUsContentSection({data}){
    return (
        <div className='laptop:pt-16'>
            {data.map(contentSectionData => {
                return <ContentSection data={contentSectionData} />
            })}
        </div>
    )
}