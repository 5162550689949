import React from 'react'; 

export default function InvestorsSection(){
    return (
        <section className='max-w-11/12 laptop:max-w-3/4 mx-auto py-10'>
            <h2 className='text-mobile-heading-lg mobile-tablet-one:text-[1.25rem] laptop:text-[2rem] mb-4 laptop:mb-7'>Investors</h2>
            <div className='grid grid-cols-4 gap-4 laptop:gap-0 items-center'>
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/stella-ventures-logo.png' className='laptop:w-[11.93rem] laptop:h-[7.93rem]' />
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/swan-logo.png' className='laptop:w-[12.5rem] laptop:h-[12.5rem]' />
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/singularity-capital-logo.png' className='laptop:w-[12.5rem] laptop:h-[12.5rem]' />
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/keiretsu-capital-logo.png' className='laptop:w-[12.5rem] laptop:h-[12.5rem]' />
            </div>
        </section>
    )
}