import React, { useContext } from 'react'; 
import Content from './Content';
import { MobileScreenContext } from '../Page';

export default function ContentSection({data}){
    const {heading, content, flexDirection, imgUrl, imgDimensions} = data;
    const { mobileScreen } = useContext(MobileScreenContext);

    return (
        <section className={`flex  ${mobileScreen && 'flex-col'}  ${!mobileScreen ? flexDirection === 'row' ? 'flex-row' : 'flex-row-reverse' : ''} max-w-11/12 mobile-lg-tablet:max-w-10/12 mobile-tablet-one:max-w-[65%] mobile-tablet-two:max-w-[55%] laptop:max-w-10/12 large:max-w-3/4 mx-auto mb-10 laptop:mb-20`}>
            <div className={`flex flex-col ${!mobileScreen ? flexDirection === 'row' ? 'mr-10': 'ml-20' : ''}`}>
                <h2 className='text-mobile-heading-lg laptop:text-3xl mb-2 laptop:mb-7 laptop:pl-0 font-medium'>{heading}</h2>
                {content.map(contentData => {
                    return <Content data={contentData} />
                })}
            </div>
            <div className='rounded-3xl mt-2 laptop:mt-0'>
                <img src={imgUrl} alt='content_img' className='rounded-3xl w-full max-h-[14.375rem] laptop:max-h-full' style={!mobileScreen ? imgDimensions : {}}/>
            </div>
        </section>
    )
}