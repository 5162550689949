import React, { useContext } from 'react'; 
import TopRightWavy from '../ui/TopRightWavy';
import { MobileScreenContext } from '../Page';
import GetStartedButton from '../ui/GetStartedButton';
import ProblemsCard from '../ui/ProblemsCard';

export default function HeroSection({data}){
    const {heading, description, benefits} = data;
    const { mobileScreen } = useContext(MobileScreenContext)

    return (
        <section className='py-7 laptop:py-14'>
            <div>
                <div className='text-mobile-base laptop:text-2xl max-w-10/12 mobile-lg-tablet:max-w-3/4 mobile-tablet-one:max-w-[65%] mobile-tablet-two:max-w-[55%] laptop:max-w-10/12 large:max-w-3/4 mx-auto'>
                    <h1 className='text-mobile-heading-xl laptop:text-5xl font-bold laptop:font-medium text-blue-800'>{heading}</h1>
                    <h3 className='py-5 laptop:w-8/12'>{description}</h3>
                    {mobileScreen && 
                        <GetStartedButton type='light'>Get Started Now</GetStartedButton>
                    }
                </div>
            
                <div className='grid gap-5 laptop:grid-cols-3 max-w-11/12 mobile-lg-tablet:max-w-10/12 mobile-tablet-one:max-w-[65%] mobile-tablet-two:max-w-[55%] laptop:max-w-11/12 large:max-w-10/12 mx-auto mt-10 laptop:mt-4'>
                    {benefits.map((benefitsContent, index) => { 
                        return <ProblemsCard data={benefitsContent} index={index} mobileScreen={mobileScreen} />
                    })}
                </div>
            </div>
            <TopRightWavy position='-top-8 -right-0'/>
        </section>
    )
}