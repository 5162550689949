import React, { useContext } from 'react'; 
import BottomLeftWavy from '../ui/BottomLeftWavy';
import { MobileScreenContext } from '../Page';

export default function LeadershipSection({data}){
    const {mobileScreen, screenWidth} = useContext(MobileScreenContext);

    return (
        <section className='
            rounded-2xl bg-blue-800 text-white 
            py-10 laptop:py-20 
            relative overflow-hidden
        '>
            <div className='
                max-w-10/12 mobile-tablet-one:max-w-[63%] mobile-tablet-two:max-w-11/12 laptop:max-w-10/12 large:max-w-3/4 
                mx-auto relative z-20
            '>
                <h2 className='
                    text-mobile-heading-xl laptop:text-[2rem] 
                    mb-7
                '>
                    Leadership team
                </h2>
                <div className='grid 
                    mobile-tablet-two:grid-cols-2 
                    gap-y-5 mobile-tablet-two:gap-x-5 laptop:gap-x-20 
                    mobile-lg-tablet:justify-center mobile-tablet-two:justify-normal
                '>
                    {data.map((teamMembersData, index) => {
                        const {name, designation, link, imgUrl} = teamMembersData;

                        return (
                            <div 
                                key={teamMembersData.name}
                                className={`flex gap-5`}
                            >
                                <div className='rounded-2xl'>
                                    <img 
                                        src={imgUrl} 
                                        alt='team_member' 
                                        className='
                                            rounded-xl 
                                            w-[7.06rem] laptop:w-[11.375rem]
                                            max-w-[7.06rem] laptop:max-w-[11.375rem] 
                                            h-auto laptop:h-[10rem]' 
                                        />
                                </div>
                                <div className='text-mobile-heading-md laptop:text-base'>
                                    <h3 className='text-mobile-heding-lg laptop:text-xl'>{name}</h3>
                                    <h4 className='font-bold py-2'>{designation}</h4>
                                    <h5 className='max-w-[72%] break-words'>
                                        <a href={link} target='_blank' className='flex items-center'>
                                            <img 
                                                src='https://looprdevstorage.blob.core.windows.net/website-temp/LinkedIn-icon.png' 
                                                alt='linkedIn_logo' 
                                                className='
                                                    w-[0.875rem] laptop:w-[1.5rem] 
                                                    h-[0.875rem] laptop:h-[1.5rem]
                                            '/>
                                        </a>
                                    </h5>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <BottomLeftWavy zPos='z-10'/>
        </section>
    )
}