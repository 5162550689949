import * as React from 'react';
import { graphql } from 'gatsby'
import Page from './../components/Page';

import Mission from '../components/About/Mission';
import ManagementTeam from '../components/About/ManagementTeam';
import CoreTeam from '../components/About/CoreTeam';
import AdvisorsTeam from '../components/About/AdvisorsTeam';
import WomanEmpowering from '../components/About/WomanEmpowering';
import NewsRoom from '../components/About/Newsroom';

import Seo from "../components/Seo";
import SetupSection from '../components/SetupSection';
import TopRightWavy from '../components/ui/TopRightWavy';
import HeroSection from '../components/AboutUs/HeroSection';
import AboutUsContentSection from '../components/AboutUs/AboutUsContentSection';
import InvestorsSection from '../components/AboutUs/InvestorsSection';
import LeadershipSection from '../components/AboutUs/LeadershipSection';

const data = {
  heroSection: {
    heading: 'About', 
    description: 'Our goal is to build factory-first AI products which integrate practically, solve key problems, derive ROI, and continuously improve over time, as signified by our name Loopr.', 
    benefits: [
      {
        title: ['Customer Focus'], 
        text: 'We are obsessed with customer success. Our products solely exist to solve manufacturing problems & derive ROI for our customers', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/slow-icon.png'
      },
      {
        title: ['Practical Solutions'], 
        text: 'We know first hand how challenging it is to change existing processes, which is why we focus on building solutions which are practical to integrate', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/packaging-regulations-icon.png'
      },
      {
        title: ['Quick Delivery'], 
        text: 'We distinguish ourselves with our ability to deliver working solutions within days, allowing our customers to test and make decisions quickly', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/high-costs-icon.png'
      },
    ]
  }, 
  contentSection: [
    {
      heading: 'Our story:', 
      content: [
          {
            text: 'Loopr was founded by Priyansha Bagaria, whose family business manufactures insulation sheets. They struggled  with manual detection of air bubbles during production. Leveraging her machine learning experience, Priyansha recognized this as an opportunity to bring the power of AI automation to the shop floor', 
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/paper-stack-icon.png', 
            labelImgDimensions: {
                minWidth: '4.37rem', 
                height: '3.62rem'
            }
          }, 
          {
            text: 'Over time, our team has built cutting-edge solutions which tackle different problems of manufacturing companies of all sizes across the globe', 
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/nested-boxes-icon.png',
            labelImgDimensions: {
                minWidth: '3.06rem', 
                height: '3.06rem'
            }
          }
      ], 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/about-us-content-01.png', 
      imgDimensions: {
        minWidth: '30.5rem', 
        height: '19.25rem'
      },
      flexDirection: 'row'
    }, 
    {
      heading: 'Company culture:', 
      content: [
          {
            text: 'We are a close knit group of engineers, data scientists & sales managers, driven by a passion to use AI to deliver practical solutions which solve problems faced by our customers', 
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/people-connected-round-icon.png', 
            labelImgDimensions: {
              minWidth: '3.75rem', 
              height: '3.62rem'
            }
          }, 
          {
            text: 'We operate on the bleeding edge of computer vision technology. Our work environment is fast-paced and open, with a flat hierarchy where everyone is valued equally, and their contributions have a direct and visible impact on our products', 
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/people-cube-icon.png',
            labelImgDimensions: {
              minWidth: '3.5rem', 
              height: '3.93rem'
            }
          }
      ], 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/about-us-content-02.png', 
      imgDimensions: {
        minWidth: '30.5rem', 
        height: '19.25rem'
      },
      flexDirection: 'row-reverse'
    }, 
  ], 
  leadershipSection: [
    {
      name: 'Priyansha Bagaria', 
      designation: 'Founder & CEO', 
      link: 'https://www.linkedin.com/in/priyanshabagaria/',
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/Priyansha-Bagaria.png'
    },
    {
      name: 'Joe Guy', 
      designation: 'Chief Product Officer', 
      link: 'https://www.linkedin.com/in/joeguy/', 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/Joe-Guy.png'
    },
    {
      name: 'Manas Patil', 
      designation: 'VP Engineering', 
      link: 'https://www.linkedin.com/in/manaspatil/',
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/Manas-Patil.png'
    },
    {
      name: 'Eric Johnston', 
      designation: 'VP Technology Solutions', 
      link: 'https://www.linkedin.com/in/ericjohnstonwa/', 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/Eric-Johnston.png'
    },
  ]
}

export default function AboutUsPage(){
  const {heroSection, contentSection, leadershipSection} = data;

  return (
    <Page navbarButtonStyle='primary' selectedPage='Company'>
      <HeroSection data={heroSection} />
      <AboutUsContentSection data={contentSection} />
      <LeadershipSection data={leadershipSection} />
      <InvestorsSection />
      <SetupSection type='primary-light' />
    </Page>
  )
  
}