import React from 'react';

export default function Content({data}){
    const {heading, text, labelImgUrl, labelImgDimensions} = data;

    return (
        <div className='grid grid-cols-8 laptop:grid-cols-6 items-center my-2 laptop:my-5'>
            <div className='mr-5 col-span-2 laptop:col-span-1 flex justify-center'>
                <img src={labelImgUrl} alt='label_img' style={labelImgDimensions}  className='scale-[65%] laptop:scale-100'/>
            </div>
            <div className='col-span-6 laptop:col-span-5'>
                {heading && <h3 className='font-bold text-mobile-heading-md laptop:text-3xl mb-3 laptop:mb-5'>{heading}</h3>}
                <p className='laptop:max-w-[78%] text-[0.75rem] laptop:text-base'>{text}</p>
            </div>
        </div>      
    )
}